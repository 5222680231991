<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество дилеров</b-tooltip>
      <div class="paycount"  id="paycount2">{{totalsum}}</div>
      <b-tooltip target="paycount2">Сумма</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="querytabledata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4" />
              <b-input-group-append>
                <b-btn size="sm" :disabled="!filter" @click="filter = ''"><b-icon-backspace></b-icon-backspace></b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" class="my-1">
          <b-form-input v-model="startDate" type="date"  size="sm" id="type-date" style="background-color: #c6ced4"></b-form-input>
        </b-col>
        <b-col md="3" class="my-1">
          <b-form-input v-model="endDate" type="date"  size="sm" id="type-date" style="background-color: #c6ced4"></b-form-input>
        </b-col>
        <b-col md="3" class="my-1">
            <b-form-radio-group v-model="selected"
                              :options="options"
                              name="radioInline"
                              size="sm"
                              style="margin-top: 5px">
            </b-form-radio-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
      >
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'dayjs';
import config from '../_services/config.json';

export default {
  name: 'supplierpayments',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //{ label: '#', key: 'index', 'class': 'text-center'},
      { label: 'Дилер', key: "name", sortable: true, 'class': 'text-left' },
      { label: 'Количество', key: "count", 'class': 'text-right' },
      { label: 'Сумма', key: "summa", sortable: true, 'class': 'text-right' },
      { label: 'Сумма без комиссии', key: "wocommission", 'class': 'text-right' },
      { label: 'Комиссия', key: "commission", 'class': 'text-right' },
      { label: 'Вознаграждение дилера', key: "dealerpercent", 'class': 'text-right' },
      { label: 'Комиссия дилера', key: "dealercomission", 'class': 'text-right' },
    ],
    selected: 'today',
    options: [
      { text: 'Сегодня', value: 'today' },
      { text: 'Вчера', value: 'yesterday' },
      { text: 'Месяц', value: 'month' }
    ],
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    //modalInfo: { title: '', content: '' },
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')

  }),
  computed: {
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
  },
  watch: {
    selected: function () {
      if (this.selected==='yesterday') {
        this.startDate=moment().add(-1,'days').format('YYYY-MM-DD')
        this.endDate=moment().add(-1,'days').format('YYYY-MM-DD')
      }
      else if (this.selected==='today') {
        this.startDate=moment().format('YYYY-MM-DD')
        this.endDate=moment().format('YYYY-MM-DD')
      }
      else {
        this.startDate=moment().date(1).format('YYYY-MM-DD')
        this.endDate=moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.totalsum=filteredItems.map(v => v.summa).reduce((total, n) => total*1 + n*1 , 0)
    },
/*    fieldlabel (rkey) {
      return _.find(this.fields,{'key': rkey})

    },*/
    expandAdditionalInfo(row) {
      //row.toggleDetails
      row._showDetails = !row._showDetails;
    },
    querytabledata () {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', startDate: this.startDate, endDate: this.endDate,type: ['DealerPayments'], token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length;
                  this.totalsum=this.items.map(v => v.summa).reduce((total, n) => total*1 + n*1 , 0)
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
    }
  },

  created() {
    this.querytabledata ()
  }
}
//<b-table striped hover :items="items" :fields="fields"></b-table>
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
